import { Navigate, createAsync, useAction } from '@solidjs/router';
import { Avatar, Button, Dialog, DialogTrigger, Link } from '@troon/ui';
import { Match, Show, Suspense, Switch, For, createMemo } from 'solid-js';
import { Title, Meta } from '@solidjs/meta';
import { gql, mutationAction, useMutation, Error404, ReservationUserState } from '../../../../graphql';
import { useUser } from '../../../../providers/user';
import { Content } from '../../../../components/content';
import { AuthFlow } from '../../../../partials/auth/auth';
import { MessageBanner } from '../../../../components/message-banner';
import { getConfigValue } from '../../../../modules/config';
import { GenericLayout } from '../../../../layouts/generic';
import { cachedQuery } from '../../../../graphql/cached-get';
import { FacilityReservationHeader } from '../../../../components/facility/reservation-header';
import { FacilityLocation } from '../../../../components/facility/location';
import { Grid, GridMain } from '../../../../components/layouts/grid';
import type {
	FacilityHeaderFragment,
	FacilityLocationFragment,
	ReservationHeaderFragment,
	ReservationUser,
} from '../../../../graphql';
import type { RouteSectionProps } from '@solidjs/router';

export default function JoinReservation(props: RouteSectionProps) {
	const user = useUser();
	const data = createAsync(
		() =>
			getReservation({
				reservationId: props.params.reservationId!,
				shareInviteId: props.params.shareInviteId!,
			}),
		{ deferStream: true },
	);

	const formData = new FormData();
	formData.set('reservationId', props.params.reservationId!);
	formData.set('shareInviteId', props.params.shareInviteId!);

	const handleJoin = useAction(useMutation(join(props.params.reservationId!)));

	const inReservation = createMemo(() => {
		return data()?.reservationInvite.users.find((resUser) => resUser.user?.id === user()?.me.id);
	});

	return (
		<GenericLayout
			hero={(data()?.reservationInvite.facility as FacilityHeaderFragment)?.metadata?.hero?.url}
			heroContent={() => (
				<MessageBanner variant="prominent">
					<p>
						<b class="font-semibold">
							{data()?.reservationInvite.owner.firstName} {data()?.reservationInvite.owner.lastName}
						</b>{' '}
						has invited you to a tee time!
					</p>
				</MessageBanner>
			)}
		>
			<Show when={data()?.reservationInvite}>
				{(invite) => (
					<Title>
						{`${invite().owner.firstName} ${invite().owner.lastName} has invited you to a tee time! | Troon`}
					</Title>
				)}
			</Show>
			<Meta
				name="og:image"
				content={`https://${getConfigValue('MAP_HOST')}/images/reservation/${props.params.reservationId}/join/${props.params.shareInviteId}/og.jpg`}
			/>
			<Meta name="description" content="Log in or sign up for Troon Rewards to accept this invitation." />

			<Suspense fallback={<div>loading…</div>}>
				<Switch>
					<Match when={user() && inReservation()}>
						<Navigate href={`/reservation/${props.params.reservationId}`} />
					</Match>
					<Match when>
						<Content>
							<FacilityReservationHeader
								bordered
								reservation={() => data()?.reservationInvite as ReservationHeaderFragment | undefined}
								user={() =>
									data()?.reservationInvite.users.find((u) => u.user?.id && u.user?.id === user()?.me.id) as
										| ReservationUser
										| undefined
								}
							>
								<div class="flex grow flex-col justify-end gap-4 sm:grow-0 sm:basis-96 md:flex-row">
									<Switch>
										<Match when={!user()}>
											<DialogTrigger>
												<Button class="shrink grow-0">Log in to accept</Button>
												<Dialog key="login-to-accept-invite">
													{(handleClose) => (
														<AuthFlow
															onComplete={async () => {
																await handleJoin(formData);
																handleClose();
															}}
														/>
													)}
												</Dialog>
											</DialogTrigger>
											<Button href="/" appearance="secondary" as={Link} class="shrink grow-0">
												Decline
											</Button>
										</Match>

										<Match when={user() && props.params.shareInviteId}>
											<Button
												onClick={async () => {
													await handleJoin(formData);
												}}
											>
												Accept
											</Button>
										</Match>
									</Switch>
								</div>
							</FacilityReservationHeader>

							<Grid>
								<GridMain>
									<h3 class="sr-only">Players</h3>

									<ul class="grid grid-cols-1 gap-4 md:grid-cols-2">
										<For
											each={data()?.reservationInvite.users.sort((a, b) => {
												if (
													a.user?.id === data()?.reservationInvite.owner.id ||
													b.state === ReservationUserState.Empty
												) {
													return -1;
												}
												if (
													b.user?.id === data()?.reservationInvite.owner.id ||
													a.state === ReservationUserState.Empty
												) {
													return 1;
												}
												return sort(
													`${a.user?.firstName ?? ''} ${a.user?.lastName ?? ''}`,
													`${b.user?.firstName ?? ''} ${b.user?.lastName ?? ''}`,
												);
											})}
										>
											{(reservationUser) => (
												<li class="flex items-center gap-x-4 overflow-hidden rounded-lg border border-neutral-500 p-4 pe-1">
													<Avatar
														class="size-12 shrink-0 grow-0 rounded-full bg-brand text-brand-100"
														firstName={reservationUser.user?.firstName ?? ''}
														lastName={reservationUser.user?.lastName ?? ''}
													/>
													<div class="flex shrink grow flex-col overflow-hidden">
														<span class="w-full overflow-hidden text-ellipsis text-nowrap">
															{reservationUser.user
																? `${reservationUser.user.firstName} ${reservationUser.user.lastName}`
																: 'Guest'}
														</span>
														<span class="text-sm">
															<Switch>
																<Match when={reservationUser.isCheckedIn}>Checked in</Match>
																<Match when={reservationUser.user?.id === data()?.reservationInvite.owner.id}>
																	Host
																</Match>
																<Match when={true}>{reservationStateToText[reservationUser.state]}</Match>
															</Switch>
														</span>
													</div>
												</li>
											)}
										</For>
									</ul>

									<hr class="my-4 border-0 border-t border-neutral-500" />

									<h2 class="text-xl font-semibold">Facility info</h2>
									<Show when={data()?.reservationInvite.facility}>
										{(facility) => <FacilityLocation {...(facility() as FacilityLocationFragment)} />}
									</Show>
								</GridMain>
							</Grid>
						</Content>
					</Match>
				</Switch>
			</Suspense>
		</GenericLayout>
	);
}

const sort = new Intl.Collator('en').compare;
const reservationStateToText: Record<ReservationUserState, string | undefined> = {
	[ReservationUserState.Invited]: 'Invited',
	[ReservationUserState.Accepted]: 'Accepted',
	[ReservationUserState.Cancelled]: 'Cancelled',
	[ReservationUserState.Empty]: undefined,
};

const query = gql(`
query reservationInvite($reservationId: String!, $shareInviteId: String!) {
  reservationInvite(reservationId: $reservationId, shareInviteId: $shareInviteId) {
		id
		...ReservationHeader
		users {
			isCheckedIn
			user {
				id
				firstName
				lastName
			}
			state
		}
		holeCount
		includesCart
		owner {
			id
			firstName
			lastName
		}
		dayTime {
			...DayTime
		}
		facility {
			...FacilityHeader
			metadata {
 			  address {
 			    street
 			    street2
 			    city
 			    state
 			    country
 			    postalCode
 			  }
 			  phone
 			  website
 			}
		}
		course {
			id
    	name
		}
	}
}
`);

const getReservation = cachedQuery(query, {
	onError: (error) => {
		if (error.graphQLErrors[0]?.message.toLowerCase().includes('not found')) {
			throw new Error404(error.graphQLErrors[0]);
		}
	},
});

const joinReservationMutation = gql(`
mutation joinReservation($reservationId: String!, $shareInviteId: String!) {
	joinReservation(reservationId: $reservationId, shareInviteId: $shareInviteId) {
		id
	}
}
`);

const join = (reservationId: string) =>
	mutationAction(joinReservationMutation, {
		revalidates: ['home', 'allReservations'],
		redirect: `/reservation/${reservationId}/`,
		toast: 'You’re all set to tee off!',
		track: {
			event: 'acceptInvitation',
			data: { reservationId },
		},
	});
